

.local-weather {

	background: url(../images/blue-lagoon-shore.jpg) left bottom no-repeat fixed #00adef;

	.wrap {
		padding: 0;
	}

	h2 {
		color: @blue-alternate;
		background: #fff;
		margin: 15px;
		padding: 15px;
	}

	.page-header {
		position: relative;
		overflow: hidden;

		.radar {
			position: absolute;
			z-index: 99;
			left: 50%;
			top: 0;
			width: 1920px;
			height: 400px;
			transform: translateX(-50%);
			max-width: inherit;
		}
	}

}

.forecast {

	.make-column(3,3,12,12);
	margin-bottom: 50px; 
	

	.panel {
		padding: 20px;
		border-radius: 0;

		.h2 {
			margin-top: 0;
			color: @blue-alternate;
		}

		.high,
		.low {
			.make-column(6,6,6,6);
			font-size: 22px;
			margin: 10px 0;

			span {
				font-size: 12px;
				text-transform: uppercase;
				display: block;
			}
		}

		.high {
			color: @orange-alternate;
		}

		.low {
			color: @blue-alternate;
		}

		.well {
			clear: both;
			padding: 5px;
			font-size: 11px;
			margin: 5px 0;
		}
	}
}


@media (max-width: @screen-sm-max) {

	.local-weather {
		background-position: center bottom !important;
	}

}