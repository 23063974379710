// Grid settings
// -------------------------

@main-sm-columns:       @grid-columns;
@sidebar-sm-columns:    4;


// Brand colors
// -------------------------

@brand-primary:   #00adef;
@brand-orange:    #338ee0;
@brand-blue: 	#00adef;

@blue-alternate:   #338ee0;
@orange-alternate: #e26829;

/* LESS / Social Media Brand Colors */

@twitter: 		#00aced;
@facebook: 		#3b5998;
@googleplus: 	#dd4b39;
@pinterest: 	#cb2027;
@linkedin: 		#007bb6;
@youtube: 		#bb0000;
@instagram: 	#517fa4;
@wordpress: 	#21759b;
@blogger: 		#fb8f3d;



// Glyphicons path
// -------------------------

@icon-font-path:        "../vendor/bootstrap/fonts/";

.blue-gradient () {
 	background: #338ee0; /* Old browsers */
 	background: -moz-linear-gradient(top,  #338ee0 0%, #5fb0f8 50%, #338ee0 99%); /* FF3.6+ */
 	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#338ee0), color-stop(50%,#5fb0f8), color-stop(99%,#338ee0)); /* Chrome,Safari4+ */
 	background: -webkit-linear-gradient(top,  #338ee0 0%,#5fb0f8 50%,#338ee0 99%); /* Chrome10+,Safari5.1+ */
 	background: -o-linear-gradient(top,  #338ee0 0%,#5fb0f8 50%,#338ee0 99%); /* Opera 11.10+ */
 	background: -ms-linear-gradient(top,  #338ee0 0%,#5fb0f8 50%,#338ee0 99%); /* IE10+ */
 	background: linear-gradient(to bottom,  #338ee0 0%,#5fb0f8 50%,#338ee0 99%); /* W3C */
 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#338ee0', endColorstr='#338ee0',GradientType=0 ); /* IE6-9 */
}

