
.sidebar {

	.make-column(3,3,4,12);
	.make-column-offset(2,1,0,0);


	h1 {
		font-size: 18px;
		color: #fff;
		margin: 0;
		padding: 20px 15px;
		font-weight: 600;
		border-bottom: 1px solid rgba(255,255,255,0.1);
	}

	.fa {
		margin-right: 5px;
	}

	.nav-collapse {

		border: 1px solid rgba(255,255,255,0.6);

		.panel {
			margin: 0;
			border: none;

			&:last-child {
				.panel-heading {
					border: none;
				}
			}

			.panel-heading {

				padding: 0;

				a {
					.blue-gradient();
					display: block;
					padding: 20px 25px;
					color: #fff;
					position: relative;


					&:hover,
					&:focus {
						text-decoration: none;
					}

					&:before {
						content: '\f0d7';
						font-family: FontAwesome;
						left: 10px;
						position: absolute;
						transition: transform 0.25s ease;
					}
				}

				.collapsed {

					&:before {
						transform: rotate(-90deg);
					}
				}
			}

			.panel-collapse {

				background: #f0f0f0;

				.nav {
					>li {
						margin: 0;

						>a {
							border-radius: 0;
							padding: 10px 20px;
							color: @blue-alternate;

							&:hover {
								background: darken(@brand-primary, 10%);
								color: #fff;
							}
						}

						&:before {
							content: '';
							color: #fff;
							font-family: 'FontAwesome';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							opacity: 0;
							filter: alpha(opacity=0);
							right: -24px;
							z-index: 1;
							border: solid;
							border-width: 22px 12px;
							border-color: transparent transparent transparent #666;
						}
						&.active {

							&:before {
								opacity: 1;
							}

							>a {
								background: #666;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	.reservation-sidebar {
		border: 1px solid rgba(255,255,255,0.6);
		background: darken(@blue-alternate, 10%);
	}

	.documents {
		border: 1px solid rgba(255,255,255,0.6);
		background: #f0f0f0;
		margin: 15px 0;

		h1 {
			color: @brand-primary;
		}

		h2 {
			margin: 0 0 10px 0;
			padding: 10px 15px;
			color: #666;
			font-size: 18px;
			border-bottom: 1px solid #ccc;
		}

		.nav {
			li {
				a {
					font-size: 14px;
					padding: 5px 15px;
				}
			}
		}
	}


	// .nav {
	// 	background: @brand-primary;


	// 	>li {

	// 		>a {
	// 			color: #fff;
	// 			transition: all 0.25s ease;
	// 			font-size: 16px;
	// 			font-weight: 600;
	// 			border-bottom: 1px solid rgba(255,255,255,.1);
	// 			padding: 20px 15px;

	// 			&:hover {
	// 				background: darken(@brand-primary, 10%);
	// 			}
	// 		}

	// 		&.active {

	// 			>a {

	// 				background: darken(@brand-primary, 10%) ;
	// 			}
	// 		}
	// 	}

	// 	.open {
	// 		a {
	// 			color: #fff;
	// 			background: #e26829;
	// 			border-color: rgba(255,255,255,0.2);

	// 			&:hover,
	// 			&:focus {
	// 				color: #fff;
	// 				background: #e26829;
	// 			}

	// 		}

	// 		.dropdown-menu {

	// 			max-height: 800px;
	// 			opacity: 1;
	// 			transition: max-height 1s ease;
	// 		}
	// 	}

	// 	.dropdown-menu {

	// 		color: #fff;
	// 		background: #e26829;
	// 		opacity: 0;
	// 		transition: max-height 1s ease, opacity 1s ease 0.5s;
	// 		width: 100%;

	// 		>li {
	// 			>a {
	// 				padding: 12px 80px 12px 40px;
	// 				color: #fff;

	// 				&:hover {
	// 					background: #00adef;
	// 				}
	// 			}
	// 		}
	// 	}

	// 	.sub-menu {

	// 		list-style-type: none;
	// 		padding: 0;
	// 		list-style-type: none;
	// 		padding: 0;
	// 		background: rgba(0,0,0,0.2);
	// 		box-shadow: 0px 1px 0px rgba(0,0,0,0.1) inset;

	// 		li {
	// 			position: relative;

	// 			a {
	// 				padding: 10px 15px 10px 15px;
	// 				display: block;
	// 				color: #fff;
	// 				transition: all 0.25s ease;
	// 				font-weight: 600;

	// 				&:hover {
	// 					background: darken(@brand-primary, 10%);
	// 					text-decoration: none;
	// 				}
	// 			}
	// 		}

	// 		.sub-menu {

	// 			li {
	// 				a {
	// 					font-weight: 400;
	// 					padding-left: 40px;
	// 				}
	// 				&:before {
	// 					content: '\f0da';
	// 					color: #fff;
	// 					display: block;
	// 					font-family: FontAwesome;
	// 					position: absolute;
	// 					top: 50%;
	// 					transform: translateY(-50%);
	// 					opacity: 0;
	// 					font-size: 40px;
	// 					left: -2px;
	// 				}
	// 				&.active {

	// 					&:before {
	// 						opacity: 1;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

}

