.home {
	.content-info { 
		margin-top: 0;
	}
}

.content-info { 
	margin-top: 40px;
}

.webmail {
    opacity: 0.01;
}

.footer-top {

	color: #fff;
	background: #272727;
	padding: 40px 0;

	.navbar-brand {
		padding: 0;
		height: auto;
	}

	.menu {
		list-style: none;
		padding: 0;

		a {
			color: #fff;
		}
	}

	.fa {
		color: #fff;
	}

	.newsletter {

		margin-bottom: 20px;
		text-align: right; 

		.h3 {
			margin-top: 0;
		}

		input {
			padding: 5px 10px;
			font-size: 16px;
		}

		a {

			&:hover {
				text-decoration: none;
			}
		}
	}

	.social {
		text-align: right;

		a {
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	.facebook {
		.fa-circle {
			color: @facebook;
		}
		 
	}

	.twitter {
		.fa-circle {
			color: @twitter;
		}
		 
	}

	.google-plus {
		.fa-circle {
			color: @googleplus;
		}
		 
	}

	.instagram {
		.fa-circle {
			color: @instagram;
		}
		 
	}

	.pinterest {
		.fa-circle {
			color: @pinterest;
		}
		 
	}

	.youtube {
		.fa-circle {
			color: @youtube;
		}
		 
	}
}

.footer-bottom {
	background: #fff;
	padding: 40px 0;

	.widget {
		display: table;
		width: 16.66666666%;
		float: left;

		a {
			width:100%;
			height:125px;
			display:table-cell;
			vertical-align: middle;
			padding: 20px;

			img{
				display:table-cell;
				height: auto;
				margin: auto;
			}
		}
	}
}

@media (max-width: @screen-sm-max) {

	.footer-top {

		.newsletter {
			margin-top: 20px;
		}
		
		.newsletter,
		.social {

			text-align: center;

		}

	}

	.footer-bottom {

		text-align: center;
	
	}

}

@media (max-width: @screen-xs-max) {

	.footer-top {

		text-align: center;

		.navbar-brand {
			padding: 0;
			margin: 0 auto 20px auto;
			display: block;
			width: 155px;
			float: none;
		}

	}

	.footer-bottom {

		.widget {
			display: table;
			width: 25%;
			float: none;
			margin:auto;
		}
	}

}