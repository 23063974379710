.gform_wrapper ul {
    .list-unstyled();
}

.gform_wrapper li {
    .form-group();
}

.gform_wrapper form {
    margin-bottom: 0;
}

.gform_wrapper .gfield_required {
    padding-left: 1px;
    color: @state-danger-text;
}

.ginput_container input,
.ginput_container select,
.ginput_container textarea {
    .form-control();
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.ginput_container textarea {
    height: auto;
}

.gform_button {
    .btn();
    .btn-primary();
}

.gform_wrapper .gfield_error {
    .gfield_label {
        color: @state-danger-text;
    }
    input,
    select,
    textarea {
        border-color: @alert-danger-border;
        background-color: @alert-danger-bg;
        color: @alert-danger-text;
        .form-control-focus(@alert-danger-text);
    }
}

.validation_error {
    .alert();
    .alert-danger();
    text-align: center;
}

#gforms_confirmation_message {
    .alert();
    .alert-success();
}

.gform_wrapper .gform_validation_container {
    display:none!important;
    position: absolute !important;
    left: -9000px;
}