body {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	padding-top: 130px;
}

a {
	transition: background 0.5s ease, color 0.5s ease; 
}


// Social Icons

.social {
	color: #a7a9ac;

	.fa-circle {
		color: #ffffff;
	}
}

.share {
	margin-bottom: 20px;
}

@media (max-width: @screen-sm-max) {

	body {
		padding-top: 114px;
	}
	
}