.validation_message {
    color: red;
    clear: both;
    border: 1px dashed red;
    padding: 3px;
    margin: 5px 0;
    background: #f2dede;
}

.gform_confirmation_wrapper {
	background: #fff;
	padding: 40px;

	.gform_confirmation_message {
		.alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
		padding: @alert-padding;
		margin-bottom: @line-height-computed;
		border: 1px solid transparent;
		border-radius: @alert-border-radius;
	}
}

.gform_body {

	.gsection_title {
		color: @brand-primary;
	}

	.gfield_description {
		margin-bottom: 10px;
		color: darken(@brand-primary, 20%)
	}

	.gform_fields {
		.make-row();
		overflow: hidden;
	}

	.gfield {
		
		.make-column(12,12,12,12);
		overflow: hidden;
		position: relative;

		label {
			display: none;
		}

		input,
		select {
			padding: 6px 15px;
			background: #fff;
		}

		.ginput_container {

			position: relative;

			&.ginput_complex {
			    overflow: hidden;
			}

			select {
				-webkit-appearance: none;
				-moz-appearance:none;
				background: transparent;
				cursor: pointer;
				position: relative;
				z-index: 2;
			}

			.name_first, 
			.name_last {
				.make-column(6,6,6,12);
			}

			.gfield_checkbox {

				li {
					margin: 0;
				}

				label {
					display: block;
					color: #333;
					display: inline-block;
				}
			}
		}

		.ginput_complex {

			.make-row();

			.ginput_full {
				.make-column(12,12,12,12);
			}

			.ginput_left,
			.ginput_right {
				.make-column(6,6,6,12);
			}

			.ginput_full,
			.ginput_left,
			.ginput_right {
				display: block;
				margin-bottom: 15px;
			}
		}
	}
	
	// Email & Phone
	#field_1_2,
	#field_1_9 {
		.make-column(6,6,6,12);
	}

	// Country Field
	#input_1_10_6_container {
		.make-column(12,12,12,12);
	}

	// Arrival Date & Accommodations
	#field_1_4,
	#field_1_42,
	#field_1_43,
	#field_1_44 {
		.make-column(4,4,6,12);
	}

	// Program Select
	#field_1_13,
	#field_1_16,
	#field_1_19,
	#field_1_22,
	#field_1_32,
	#field_1_45  {
		.make-column(12,12,12,12);
		margin-bottom: 0;
	}
	
	// Quantity
	#field_1_14,
	#field_1_18,
	#field_1_20,
	#field_1_23,
	#field_1_27,
	#field_1_28,
	#field_1_31,
	#field_1_30,
	#field_1_37,
	#field_1_38,
	#field_1_39,
	#field_1_46,
	#field_1_47 {
		.make-column(2,2,2,12);
		margin-bottom: 30px;
	}

	// Age Groups
	#field_1_17,
	#field_1_15,
	#field_1_21 {
		.make-column(6,6,6,12);
		margin-bottom: 30px;
	}

	// Select box styles
	#input_2_10_6_container,
	#input_1_10_6_container {

		&:after {
		    content: '\f078';
		    font-family: FontAwesome;
		    position: absolute;
		    right: 16px;
		    top: 0;
		    font-size: 18px;
		    background: #fff;
		    color: #666;
		    padding-right: 12px;
		    bottom: 0;
		    z-index: 1;
		    width: 50px;
		    text-align: right;
		    line-height: 40px;
		    left: 16px;
		    width: auto;
		}

	}
}

.gform_footer {

	text-align: right;

	.gform_button {

		padding: 14px 80px;
		font-size: 20px;
		border: none;
		background: #e57927; /* Old browsers */
		background: -moz-linear-gradient(top,  #e57927 0%, #e28f53 50%, #e57927 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e57927), color-stop(50%,#e28f53), color-stop(100%,#e57927)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top,  #e57927 0%,#e28f53 50%,#e57927 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top,  #e57927 0%,#e28f53 50%,#e57927 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(top,  #e57927 0%,#e28f53 50%,#e57927 100%); /* IE10+ */
		background: linear-gradient(to bottom,  #e57927 0%,#e28f53 50%,#e57927 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e57927', endColorstr='#e57927',GradientType=0 ); /* IE6-9 */


		&:hover,
		&:focus {
			background: darken(#e57927, 10%);
			outline: none;
		}

	}

}

// Checkbox

.gfield_checkbox {

	input {

		display: none !important;

		&:checked {

			& + label {

				&:before {

					content: '\f00c';

				}

			}

		}

	}

	label {
		
		display: block !important;
		padding-left: 35px;
		font-size: 18px;
		cursor: pointer;

		&:before {

			display: block;
			content: '';
			width: 25px;
			height: 25px;
			background: #fff;
			position: absolute;
			left: 0px;
			border: 1px solid #666;
			color: #666;
			font-family: 'FontAwesome';
			line-height: 1.4em;
			text-align: center;

		}
	}
}

// Checkbox Horizontal

.checkbox-horizontal .gfield_checkbox li {
  position: relative;
  float: left;
  margin-right: 20px !important;
}

// Radio

.gfield_radio {

	input {

		display: none !important;

		&:checked {

			& + label {

				&:before {

					content: '\f111';
					padding: 0px 4px;
				}
			}
		}
	}

	label {
		
		display: block !important;
		padding-left: 35px;
		font-size: 18px;
		cursor: pointer;

		&:before {

			display: block;
			content: '';
			width: 25px;
			height: 25px;
			background: #fff;
			position: absolute;
			left: 0px;
			border: 1px solid #666;
			color: #666;
			font-family: 'FontAwesome';
			line-height: 1.4em;
			text-align: center;
			border-radius: 50%;

		}
	}
}


// Date Picker

#ui-datepicker-div {

	background: #f0f0f0;
	padding: 20px;
	position: absolute;
	display: none;
	z-index: 2 !important;
	box-shadow: 0 1px 3px rgba(0,0,0,0.3);

	.ui-datepicker-header {

		// overflow: hidden;

		.ui-corner-all {
			background: #e57927;
			color: #fff;
			border-radius: 3px;
			padding: 5px 10px;
			
			&:hover {
				cursor: pointer;
			}
		}
		
		.ui-datepicker-prev {
			float: left;
		}

		.ui-datepicker-next {
			float: right;
		}

		.ui-datepicker-title {
			margin: 0 0 14px 0;
			text-align: center;

			.ui-datepicker-month {
				margin-right: 5px;
			}
		}
	}

	.ui-datepicker-calendar thead {
	    background: #ccc;
	    font-size: 20px;
	}

	.ui-datepicker-calendar {

		th {
		    font-weight: 300;
		    padding: 10px;
		    text-align: center;
		}

		td {
			    background: #fff;
			    padding: 10px;
			    text-align: center;
			
			&:hover {
				background: #f0f0f0;
				cursor: pointer;
			}
		}

	}

}

@media (max-width: @screen-sm-max) {

	.gform_body {

		#field_1_4,  
		#field_1_5,
		#field_1_42,
		#field_1_43,
		#field_1_44 {
			float: none;
			width: 100%;
			margin-right: 0;
		}

	}

}

@media (max-width: @screen-xs-max) {

	.gform_body {

		#field_1_2 {

			width: 100%;
			float: none;
			margin-right: 0;
		
		}

		.gfield  {

			.ginput_container {

				input,
				select,
				textarea {

					padding: 20px;

				}

				.name_first input {

					margin-bottom: 15px;

				}

				.name_first,
				.name_last {

					input {

						float: none;
						width: 100%;

					}

				}

			}

		} 

		#field_1_5,
		#field_1_6 {

			&:after {

				padding: 16px 12px;

			}

		}

	}

	.gform_footer {

		.gform_button {

			width: 100%;

		}

	}

	#ui-datepicker-div {

		left: 0 !important;
		width: 100%;
		padding: 10px;

		.ui-datepicker-calendar {

			width: 100%;

		}

	}

}

