.home {
	
	.navbar {
		margin-bottom: 0;
	}

.specials {
	background: #f38322;
	color: #fff;
	padding: 1em 0;
	figure {
		.make-column(1,1,6,6);
		.make-column-offset(0,0,3,3);
		img {
			top: .5em;
			position: relative;
			display: block;
			margin: auto;
		}
	}
	section {
		.make-column(8,8,12,12);
		h1 {
			font-size: 43px;
			margin-top: 0;
			text-transform: uppercase;
			font-weight: 300;
		}
		p {
			text-transform: uppercase;
			margin-bottom: 0;
			font-weight: 800;
    		font-size: 24px;
		}
	}
	.btn {
		.make-column(2,2,12,12);
		top: 1.5em;
		color: #fff;
		font-size: 1em;
		border: 2px solid #fff;
		border-radius: 4px;
		padding: 0.5em;
		background: rgba(255,255,255,0);
		&:hover {
			text-decoration: none;
			background: rgba(255,255,255,0.4);
		}
	}
}

.intro {

	.blue-gradient();
	color: #ffffff; 
	padding: 35px 0 45px;
	border-top: solid 2px #272727;
	font-size: 16px;
	position: relative;

	// &:before {
	// 	content: '\f078';
	// 	width: 90px;
	// 	height: 90px;
	// 	background: #272727;
	// 	text-align: center;
	// 	font-family: 'FontAwesome';
	// 	border-radius: 50%;
	// 	padding: 5px 0;
	// 	font-size: 57px;
	// 	position: absolute;
	// 	left: 50%;
	// 	top: -48px;
	// 	margin-left: -2.5%;
	// }
	figure {
		.make-column(5,8,10,12);
		.make-column-offset(0,2,1,0);
	}


	section {
		.make-column(7,10,10,12);
		.make-column-offset(0,1,1,0);

		img {

			margin: 0 auto;
		
		}
	
	}

	section {

		h1 {
			font-weight: 300;
			font-size: 53px;
			margin: 0 0 5px;
			line-height: 1em;
		}

	}
	
}

.programs {

	background: url(../images/blue-lagoon-beach.jpg) left top no-repeat fixed;
	color: #fff;
	padding: 20px 0;

	section {

		width: 49%;
		padding: 20px; 
		min-height: 200px;
		margin: 0px 0 20px;
		float: left;
		opacity: 0;
		transition: opacity 1s ease, margin-top 1s ease;
		box-shadow: 3px 3px 2px rgba(0,0,0,0.4);
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 60px;
			height: 60px;
			background: url(../images/starfish.png) left top no-repeat;
			position: absolute;
			right: 10px;
			bottom: 10px;
			background-size: contain;
		}

		&:nth-child(odd) {
			margin-right: 2%;
		}

		&.on {
			opacity: 1;
		}

		figure {
			width: 25%;
			float: left;
			margin-right: 15px;
		}

		h1 {
			margin: 0 0 20px 0;
			font-weight: 300;
		}

		p {
			overflow: hidden;
		}

		&:nth-child(odd) {
			clear: both;
		}

		a {
			color: #fff;

			&:hover {
				color: #fff;
				background: rgba(255, 255, 255, 0.1);
			}
			&:last-child {
				font-weight: 700;
			}
		}

		.btn {
			background: rgba(255, 255, 255, 0.2);
			border: none;
		}

		.btn-group {
			float: right;
			margin-right: 60px;
		}
	}
}

.review {

	background: #00adef;
	color: #ffffff; 
	padding: 30px 0;
	border-top: solid 2px #272727;
	border-bottom: solid 2px #272727;
	font-size: 20px;
	position: relative;

	blockquote {
		font-size: 44px;
		border-left: none;
		padding: 0;
		margin: 0;
		font-weight: 300;


		p {
			margin: 0;
		}

		footer {
			color: #fff; 
			text-align: right;
		}
	}
}

.gallery {
	
	overflow: hidden;
	border-bottom: solid 2px #272727;

	figure {
		float: left;
		width: 14.285%;
	}
}

.beach {
	background: url(../images/blue-lagoon-shore.jpg) left top no-repeat fixed @brand-blue;
	padding: 40px 0;

	.h1 {
		color: #fff;
		font-size: 42px;
		text-shadow: 0 1px 1px rgba(0,0,0,0.8);
	}

	.reservation {
		padding: 60px 0;
	}

	.news {

		margin: 0;

		.make-row();
	
		section {

			.make-column(6,6,6,6);

			figure {

				background: #272727;
				color: #fff;
				border: solid 1px #fff;
				opacity: 0;
				transition: opacity 1s ease;

				&.on {
					opacity: 1;
				}

				img {
					width: 100%;
				}

				figcaption {
				    padding: 20px;
				    font-size: 18px;
				}

				h1 {
				    font-weight: 300;
				    color: #00adef;
				    margin: 0;
				}

				p {
				    margin-bottom: 0;
				}

				a {
				    color: #00adef;
				}

			}

		}

	}

}

}

@media (max-width: @screen-md-max) {
.home {

	.specials {

		section {
			h1 {
				font-size: 37px;

			}
			p {
	    		font-size: 22px;
			}
		}
	}

	.intro {

		text-align: center;

		section {

			h1 {
				margin: 15px 0;

			}
		}
	}


	.programs {

		background: url(../images/blue-lagoon-beach-md.jpg) left top no-repeat fixed;

		section {
			
			text-align: center;
			min-height: 340px;

			figure {

				float: none;
				width: 30%;
				margin: 0 auto 20px auto;

				img {

					margin: auto;

				}

			}	

		}
		
	}

	.gallery {

		figure {

			width: 16.66666%;
			
			&:nth-child(13),
			&:nth-child(14) {
				display: none; 

			}

		}

	}

	.beach {

		background: url(../images/blue-lagoon-shore-md.jpg) left top no-repeat fixed #efe0cb;

	}
}

}

@media (max-width: 1025px) {

.home {

	.programs {

		background: url(../images/blue-lagoon-beach-md.jpg) left top no-repeat #efe0cb;
		background-attachment: scroll;
		background-position: center top !important;
		
	}

	.beach {
		
		background: url(../images/blue-lagoon-shore-md.jpg) left top no-repeat #efe0cb;
		background-attachment: scroll;
		background-position: center top !important;

	}

}
}


@media (max-width: @screen-sm-max) {

.home {

	.specials {

		figure {
			img {
				position: static;
				margin: 1em auto;
			}
		}

		section {
			text-align: center;
			h1 {
				font-size: 37px;

			}
			p {
	    		font-size: 22px;
			}
		}

		.btn {
			position: static;
			margin: 1em 0;
		}
	}

	.intro {

		text-align: center;
		padding: 40px 0;

		section {

			h1 {
				font-size: 8vw;
				margin: 15px 0;

			}
		}
	}

	.programs {

		background: url(../images/blue-lagoon-beach-sm.jpg) left top no-repeat fixed #efe0cb;
		background-attachment: scroll;
		background-position: center top !important;

		section {
		
			min-height: 370px;

			figure {

				width: 40%;

			}	

		}
		
	}

	.gallery {

		figure {

			width: 20%;
			
			&:nth-child(11),
			&:nth-child(12), {
				display: none; 

			}

		}

	}
	
	.beach {
		
		background: url(../images/blue-lagoon-shore-sm.jpg) left top no-repeat #efe0cb;
		background-attachment: scroll;
		background-position: center top !important;

	}

}
}

@media (max-width: @screen-xs-max) {

.home {

	.intro {

		font-size: 4.5vw;
	}

	.programs {

		background: url(../images/blue-lagoon-beach-xs.jpg) left top no-repeat #efe0cb;
		background-attachment: scroll;
		background-position: center top !important;

		section {
		
			width: 100%;
			min-height: inherit;
			margin-top: 0;	

		}
		
	}

	.review {

		blockquote {

			font-size: 6vw;

		}
	
	}

	.gallery {

		figure {

			width: 33.3333%;
			
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9),
			&:nth-child(10), {
				display: none; 

			}

		}

	}

	.beach {

		background: url(../images/blue-lagoon-shore-xs.jpg) left top no-repeat #efe0cb;

		.reservation {
			
			padding: 20px 0;

		}

		.news {

			section {

				width: 100%;
				margin-bottom: 20px;

			}

		}

	}

}

}
