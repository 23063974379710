.banner { }

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
	color:#fff !important;
}

.navbar {

	border: none;
	margin-bottom: 0;
	position: fixed;
	top: 0px;
	transition: all 0.5s ease;
	width: 100%;
	background: none;
	-webkit-backface-visibility:hidden;

	.navbar-brand {

		top: 0;
		transition: all 0.5s ease;
		z-index: 10;
		padding: 0;
		height: 50px;

		img {
			transition: all 0.5s ease;
			height: auto;
			margin: 0 auto 10px auto;
			width: 175px;
		}

		.logo {
			opacity: 1;
			top: 5px;
			position: absolute;
		}

		.logo-pill {
			opacity: 0;
			top: -50px;
			position: absolute;
		}

	}


	&.offScreen {

		position: fixed;
		top: 0;

		.header-top {

			height: 0;
			margin: 0;
			padding: 0;
			overflow: hidden;
		}

		.navbar-brand {

			.logo {
				opacity: 0;
				top: -155px;
			}

			.logo-pill {
				opacity: 1;
				top: 14px;
			}
		}
	}

	.header-top {

		.blue-gradient();		
		padding: 5px 0;
		transition: all 0.5s ease;
		overflow: hidden;
		height: 60px;

		.phone {
			font-size: 17px;
			padding: 10px;
			margin: 0;
			color: #fff;
			
			span {
				font-weight: 600;	
			}

			a {
				color: #fff;
			}
		}
		
		.phone,
		.social {
			float: right;
		}

		.current-conditions {
			width: 31px;
			line-height: 40px;
			margin: 0 0 0 10px;

			img {
				max-width: 100%;
			}
		}
	}

	.main-header {

		background: #272727;
		font-size: 18px;
		padding: 10px 0;

		a {
			color: #fff;
		}
		
	}

	.navbar-nav {

		float: right;

		li {
			a {

				color: #fff;
				font-weight: 300;
				padding: 15px 40px;
				transition: background 1s ease 0.5s, color 1s ease 0.5s;

				&:hover {
					color: #fff;
					background: @brand-orange;
					transition: background 1s ease, color 1s ease;
				}

				&.dropdown-toggle {

					.fa {
						margin-left: 5px;
					}
				}
			}

			&:focus {

				color: #fff;
				font-weight: 300;
				padding: 15px 40px;
				transition: background 1s ease 0.5s, color 1s ease 0.5s;

			}
		}

		.open {
			a {
				color: #fff;
				background: #e26829;

				&:hover,
				&:focus {
					color: #fff;
					background: #e26829;
				}

			}

			.dropdown-menu {

				max-height: 800px;
				opacity: 1;
				transition: max-height 1s ease;
			}
		}

		.dropdown-menu {

			color: #fff;
			background: #e26829;
			opacity: 0;
			transition: max-height 1s ease, opacity 1s ease 0.5s;

			li {
				a {
					padding: 12px 80px 12px 40px;

					&:hover {
						background: #00adef;
					}
				}
			}
		}

	}

}

@media (max-width: 1350px) {

	.navbar {

		.navbar-nav {

			.dropdown-menu {

				left: auto;
				right: 0;
				text-align: right;

				li {

					a {

						padding: 12px 40px 12px 80px;
					
					}
				
				}

			}

		}

	}

}

@media (max-width: @screen-md-max) {

	.navbar {

		.navbar-nav {

			>li {

				a {

					padding: 15px 35px;

				}
			}
		}

	}

}

@media (max-width: @screen-sm-max) {

	.navbar {

		// min-height: 332px;

		.header-top {

			.navbar-brand {

				position: static;
				width: 155px;
				margin: auto;
				float: none;

				.logo {
					opacity: 0;
					display: none;
				}


				.logo-pill {
					opacity: 1;
					top: 8px;
				}

			}
			.social {
				display: none;
			}	
		}

		&.offScreen {

			.header-top {

				.navbar-brand {

					.logo-pill {

						top: 14px;
					}
				}
			}
		}

		.navbar-nav {

			>li {

				a {

					padding: 15px;
					text-align: center;
					font-size: 18px;
				}
			}
		}

	}

}

@media (max-width: @screen-xs-max) {

	.sidebar {
		width:93% !important;
	}

	.navbar {

		// min-height: 316px;

		.header-top {

			.navbar-brand {

				.logo-pill {

					top: 66px;
				}
			}

			.phone {
				text-align: center;
				width: 100%;
				font-size: 4vw;
				padding: 10px 0;
			}
			.current-conditions {
				display: none;
			}
		}

		&.offScreen {

			.header-top {

				.navbar-brand {

					.logo-pill {

						top: 6px;
					}
				}
			}
		}

		.main-header {

			padding: 0;

			.navbar-collapse {
				overflow-y: scroll;
				overflow-x: hidden;
			}

			.container {

				padding: 0;

				.navbar-header {

					margin: 0;
				}
			
			}
			
		}

		.navbar-toggle {

			border-radius: 0;
			border: none;
			padding: 5px 12px;
			color: #ccc;

			&:hover,
			&:focus {

				background: rgba(255,255,255,0.25);

			}

		}

		.navbar-nav {

			float: none; 
			margin: 0;
			overflow: scroll;

			li {

				width: 100%;

				a {

					text-align: left;
					padding-left: 15px;
					background: @brand-blue;
					margin-bottom: 1px;
					font-weight: 300;

					&:hover,
					&:focus {

						background: rgba(255, 255, 255, 0.25);

					}

				}

				&.open {

					.dropdown-toggle,
					.dropdown-toggle:hover,
					.dropdown-toggle:focus,
					.dropdown-toggle:focus:hover {

						background: rgba(255,255,255,0.25);
					
					}

					.dropdown-menu {

						background: none;

						a {
							
							background: rgba(255,255,255,0.25);
							color: #fff;

							&:hover {
								color: #fff;
							}
					
						}

					}

				}

			}

		}

	}

}











