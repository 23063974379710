.page-template-park-programs,
.dolphin-swim-programs,
.page-template-blue-lagoon {
	background: url(../images/blue-lagoon-beach.jpg) left top repeat fixed;
}


.park-program {

	background: #fff;
	padding: 0 20px 20px 0;
	box-shadow: 1px 4px 3px rgba(0,0,0,.5);
	border: 1px solid rgba(255,255,255,0.6);

	>header {

		.make-row();

		.program-logo {

			padding: 0;

			img {
				width: 100%;
			}

		}

		.program-title {

			margin: 0;
			padding: 10px;
			color: #fff;

			img {
				width: 75px;
				display: inline-block;
			}
		}

		.program-details {
			
			  overflow: hidden;
			  font-size: 16px;
			  padding: 10px 0;
			  background: #f0f0f0;

			>li {
				.make-column(3,3,12,12);
				list-style-type: none;

				h4 {
					color: @brand-primary;
				}

				>ul {
					overflow: hidden;
					padding: 0;

					>li {
						list-style-type: none;
						clear: both;
					}
				}

			}

			.note {
				margin: 0 10px 0 0;
				clear: both;
				text-align: right;
				font-size: 12px;
			}

		}
	}

	.embed-responsive {
		margin: 20px 0;
	}
}

.swim-program {

	.make-row();
	margin: 25px 0;
	overflow: hidden;

	img {
		.make-column(3,3,3,4);
		padding-left: 0;
	}

	h1 {
		margin-top: 0;
	}

	.btn {
		float: right;
	}
}


@media (max-width: @screen-xs-max) {

	.park-program {

		>header {

			.program-details {

				text-align: center;

				img {
					display: block;
					margin: 20px auto;
				}

			}
		}
	}


}