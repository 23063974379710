

.reservation-sidebar {

	background: #333;
	color: #fff;
	padding: 10px;
	margin: 25px 0;

	.gform_body {

		.gfield {

			.ginput_container {

				.name_first, 
				.name_last {
					.make-column(12,12,12,12);
					margin-bottom: 15px;
				}
			}

			.ginput_complex {

				.make-row();

				.ginput_full {
					.make-column(12,12,12,12);
				}

				.ginput_left,
				.ginput_right {
					.make-column(12,12,12,12);
				}

				.ginput_full,
				.ginput_left,
				.ginput_right {
					display: block;
					margin-bottom: 15px;
				}
			}
		}

		#field_3_1,
		#field_3_10 {
			margin-bottom: 0;
		}

		#field_2_2,
		#field_2_9 {
			.make-column(12,12,12,12);
		}
	}

	.gform_footer .gform_button {
		padding: 20px 20px;
		font-size: 20px;
		background: #e57927;
		border: none;
		width: 100%;
		text-align: center;
	}
}


// Address Field formatting (Gravity forms bad markup forces me to do this, sorry :( )

#input_1_10_3_container,
#input_1_10_4_container,
#input_1_10_5_container {

	.make-column(4,4,4,12);
}

#field_2_2,
#field_2_9 {
	.make-column(6,6,6,12);
}


