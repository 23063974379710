.carousel-responsive {
	
	overflow: hidden;

}

#carousel {

	.item {

		background-position-x: center;
		background-position-y: top;
		background-repeat: no-repeat;
		background-size: cover;
		transition: transform .6s ease;
	}

}

.carousel {

	.item {

		img {
			width: 100%;
		}
	}
}

.mobile-hero {

	img {

		width: 150%;
		height: auto;
		margin-left: -25%;

	}
}

.carousel-control {

	opacity: 0.75;
	font-size: 40px;

	&.left,
	&.right {
		background-image:none;
	}
	
	.icon-prev {
		
		&:before {
			content: '\f053';
			font-family: 'FontAwesome';	
		}
	}

	.icon-next {
		
		&:before {
			content: '\f054';
			font-family: 'FontAwesome';	
		}
	}
}

.home {
	
	.carousel-indicators {
		list-style-type: none;
		position: absolute;
		z-index: 10;
		bottom: 7px;
		left: 50%;
		transform: translateX(-50%);
		margin: 0;
		padding: 0;
		width: auto;

		li {
			float: left;
			border: 2px solid #FFF;
			cursor: pointer;
			transition: all .5s ease 0.5s;
			border-radius: 50%;
			padding: 10px;
			margin-right: 21px;
			transform: scale(.5);
			background: rgba(255,255,255,0);
			
			&.active {
				transform: scale(.75);
				background: rgba(255,255,255,1);	
			}

			&:hover {
				opacity: 0.5;
			}
		}
		
	}

}

body:not(.home) {
	
	.carousel-indicators {
		
		list-style-type: none;
		padding: 0;
		margin-bottom: 25px;
		overflow: hidden;

		li {
			float: left;
			width: 25%;
			border: 1px solid #fff;
			
		}
		
	}
}



// Carousel Captions 

.carousel-caption {

	width: 100%;
	padding: 19% 0 2%;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0,0,0,0.8);
	position: static;

	a {
		color: #fff;
		display: inline-block;
		transform: scale(1);
		transition: transform 0.25s ease;

		&:hover {
			transform: scale(1.1);
		}
	}

	section {
		display: inline-block;
		background: rgba(0,0,0,.5);
		border-radius: 0;
		padding: 20px;
		border-top: 8px solid @blue-alternate;

		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 15px;
		// 	right: 15px;
		// 	height: 10px;
		// 	.blue-gradient();
		// }
	}

	h1 {
		font-size: 46px;
		text-transform: uppercase;
		margin: 0;
	}

	.h2 {
		font-size: 25px;
		text-transform: uppercase;
		margin: 0;
	}

	.h4 {
		text-transform: uppercase;
		font-weight: 300;
		font-size: 32px;
		margin: 0;
	}

}

@media (max-width: @screen-md-max) {

	#carousel {

		.item {

			.carousel-caption {

				padding: 32% 0 2%;

			}

		}

	}

}

@media (max-width: @screen-sm-max) {

	#carousel {

		.item {

			background-attachment: scroll;
			background-position: center top !important;

			.carousel-caption {

				padding: 40% 0 2%;

			}

		}

	}

}

@media (max-width: @screen-xs-max) {

	#carousel {

		.item {

			height: 230px;

			.carousel-caption {

				padding: 0;
				bottom: 0;
				left: -15px;
				position: absolute;

				section {
					padding: 5px 10px;
				}

				.h4 {
					font-size: 4vw;
				}

				h1 {
					font-size: 6vw;
				}

				.h2 {
					font-size: 3vw;
				}

			}

		}

	}

	.carousel-control {

		opacity: 0.75;
		font-size: 16px;

	}


}





