
.page {

	&.sidebar-primary {

		.main {
			.make-row();
		}

		article {
			.make-column(5,7,8,12);
		}
		article.page {
			figure {
				max-width: 100%;
				padding: 0;
				border-radius: 0;
				
				figcaption {
					text-align: center;
				}
			}
		}
	}
}





/* Book a Reservation */

.book-a-reservation {
	background: url(../images/blue-lagoon-shore.jpg) left top no-repeat fixed #00adef;

	.wrap {
		padding: 0;
	}

	#gform_wrapper_1 {
		background: rgba(255,255,255,0.9);
		padding: 20px;
	}
}

@media (max-width: @screen-sm-max) {

	.book-a-reservation {
		background-position: center bottom!important;
	}

}


/* Landing Page Template */

.page-template-landing-page {
	background: url(../images/blue-lagoon-beach.jpg) left top no-repeat fixed #00adef;

	.landing-page {
		background: #fff;

		>header {
			.make-row();

			.featured-image {
				img {
					width: 100%;
					border: 1px solid #fff;
				}
			}
		}
	}
}


@media (max-width: @screen-sm-max) {

	.page-template-landing-page {
		background-position: center bottom!important;
	}

}

/* Program Page Template */

.page-template-program-page {
	background: url(../images/blue-lagoon-beach.jpg) left top no-repeat fixed #00adef;

	.program-page {
		background: #fff;

		>header {
			.make-row();

			.featured-image {
				img {
					width: 100%;
					border: 1px solid #fff;
				}
			}
		}
	}
}


@media (max-width: @screen-sm-max) {

	.page-template-program-page {
		background-position: center bottom!important;
	}

}

/* Learn Research Template */

.page-template-learn-research {
	background: url(../images/blue-lagoon-beach.jpg) left top no-repeat fixed #00adef;

	.learn-research {
		background: #fff;

		>header {
			.make-row();

			.featured-image {
				img {
					width: 100%;
					border: 1px solid #fff;
				}
			}
		}
	}
}


@media (max-width: @screen-sm-max) {

	.page-template-landing-page {
		background-position: center bottom!important;
	}

}


/* Contact */

.contact {

	.container-fluid {
		padding: 0;
	}

	.gform_wrapper {

		.make-column(8,8,12,12);
		.make-column-offset(2,2,0,0);
	}
	
	// Google Maps Info Window Styles
	/* white background and box outline */
	.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div {
	    /* we have to use !important because we are overwritng inline styles */
	    background-color: transparent !important;
	    box-shadow: none !important;
	    width: auto !important;
	    height: auto !important;
	    border: none !important; 
	}

	/* arrow colour */
	.gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div > div > div {
	    background-color: transparent !important;
	    box-shadow: none !important;
	    display: none !important;
	    border: none !important; 
	}

	/* close button */
	.gm-style > div:first-child > div + div > div:last-child > div > div:last-child {
	    margin-right: 5px;
	    margin-top: 5px;
	}

	/* image icon inside close button */
	.gm-style > div:first-child > div + div > div:last-child > div > div:last-child > img {
	    display: none;
	}

	/* positioning of infowindow */
	.gm-style-iw {
	      top: 37px!important;
  		left: 15px!important;
	}


}
