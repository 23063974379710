.size-full {
	height: auto;
}

.single,
.archive,
.category {

	.sidebar {
	  .make-column(3,3,3,12);
	  .make-column-offset(1,1,1,0);
	}

}

.blog {
	background: url('../images/blue-lagoon-shore.jpg') center center no-repeat fixed;
	background-position: center center !important;

	&:after {
		display: block;
		content: '';
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(255,255,255,.50);
		z-index: -1;
	}

	.main {
		
		.blog-header {
			.make-column(8,10,12,12);
			.make-column-offset(2,1,0,0);
			background: #fff;
			padding: 20px;
			margin-top: 30px;
			margin-bottom: 20px;

			h1 {
				margin: 0;
				color: @brand-primary;
			}
		}

		.blog-footer {
			.make-column(8,8,8,12);
			.make-column-offset(2,2,2,0);
			margin-top: 20px;
			margin-bottom: 20px;
			text-align: center;
		}

		.post {
			.make-column(8,10,12,12);
			.make-column-offset(2,1,0,0);
			background: #fff;
			margin-bottom: 10px;
			padding: 0;
			border: 1px solid #fff;
			box-shadow: 0 1px 2px rgba(0,0,0,0.25);

			figure {
				.make-column(5,5,5,12);
				padding: 0;
				background: fade(@brand-primary, 10%);

				svg {
					width: 200px;
					margin: auto;
					display: block;
				}
			}

			header {
				.make-column(7,7,7,12);
				position: static;
				margin: 15px 0;

				h1 {
					font-size: 30px;
					margin: 0 0 5px 0;
				}

				a {
					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}
			
			.entry-meta {
				color: #666;
			}

			.entry-summary {
				.make-column(7,7,7,12);
				position: static;
			}

			.category-name {
				position: absolute;
				left: -1px;
				top: 0;
				background: #fff;
				padding: 10px 20px;
			}

			.read-more {
				position: absolute;
				bottom: -1px;
				right: 15px;
				border-radius: 0;
			}
		}
	}
}

.single {
	background: url('../images/blue-lagoon-shore.jpg') center center no-repeat fixed;
	background-position: center center !important;

	&:after {
		display: block;
		content: '';
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(255,255,255,.50);
		z-index: -1;
	}

	.wrap {
		margin: 50px auto;
		padding-bottom: 25px 0;
	}


	.post {
		.make-column(10,12,12,12);
		.make-column-offset(1,0,0,0);


		header {
			width: 39%;
			float: left;
			background: rgba(255,255,255,0.96);
			padding: 25px;
			margin-right: 2%;
			position: relative;

			.share {
				position: absolute;
				margin-bottom: 0;
				bottom: 0;
				right: 0;
				
				.btn-group {
					border-radius: 0;

					a {
						border: none;
						border-radius: 0;
					}
				}

			}
		}

		.entry-content {
			width: 59%;
			float: left;
			background: rgba(255,255,255,0.96);
			padding: 25px;
		}

		.entry-title {
			font-weight: 300;
			color: @brand-primary;
		}

		.entry-meta {

			margin-bottom: 25px;

			time,
			.author {
				display: inline-block;
				margin-right: 10px;

				.fa {
					margin-right: 5px; 
				}
			}

			.category-name {
				position: absolute;
				bottom: -35px;
				background: rgb(240, 240, 240);
				padding: 5px 20px;
				left: 0px;
			}
		}

		.entry-content {

			font-size: 18px;
			
			p {
				margin-bottom: 26px;
			}
		}

	}
}


// User Entered Content
.hentry {

	font-size: 18px;

	h1 {
		color: @brand-primary;
		font-weight: 300;
	}

	h2 {
		color: @brand-primary;
		font-weight: 600;
		font-size: 20px;
	}

}

.hentry header { }
.hentry time { }
.hentry .byline { }

.hentry footer { }

@media (max-width: @screen-md-max) {

	.blog {

		.main {
			
			.post {

				font-size: 1.6vw;
				
				header {

					margin-bottom: 15px;

					h1 {
						font-size: 2.5vw;
					}
				}
			}
		}
	}

	.single {

		.post {

			header {
				width: 49%;
			}

			.entry-content {
				width: 49%;
			}
		}
	}

}

@media (max-width: @screen-sm-max) {

	.blog {

		.main {
			
			.post {

				font-size: 2vw;
				
				header {

					margin-bottom: 15px;

					h1 {
						font-size: 3vw;
					}
				}
			}
		}
	}

	.single {

		.post {

			header {
				width: 100%;
				margin: 0 0 40px 0;
			}

			.entry-content {
				width: 100%;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {

	.blog {

		.main {
			
			.post {

				font-size: 4vw;
				
				header {

					margin-bottom: 15px;

					h1 {
						font-size: 6vw;
					}
				}

				.entry-summary {
					margin-bottom: 35px;
				}

				.read-more {
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}
}
