
.page-header {

	margin: 0 0 25px 0;
	text-align: center;
	border: none;
	color: #fff;


	h1 {
		margin: 0;
		padding: 10px;
		box-shadow: 0 1px 6px rgba(0,0,0,.5);
		.blue-gradient();
	}
}

@media (max-width: @screen-xs-max) {

	.page-header {

		overflow: hidden;

		img {
			max-width: 160%;
			margin-left: -30%;
		}

		h1 {
		 	font-size: 6vw;
		}

	}

}