@starfish: 	#bd8cbf;
@turtle: 	#007236;
@angelfish: #f7941d;
@dolphin: 	#00aeef;

.page-template-education-programs {
	background: url(../images/blue-lagoon-beach.jpg) left top repeat fixed;
}


.education-program {
	background: #fff;
	padding-top: 15px;

	>header {

		.make-row();

		.program-logo {

			.make-column(6,6,6,12);

			img {
				width: 225px;
				margin: auto;
			}
		}

		.program-details {

			.make-column(6,6,6,12);
			overflow: hidden;
			font-size: 16px;

			>dt {
				color: @brand-primary;
				width: 100px;
				float: left;
				text-align: right;
				margin-right: 15px;
				margin-bottom: 8px;
				clear: both;
				border-right: 2px solid;
				padding-right: 10px;
			}

			>dd {
				width: 50%;
				float: left;
			}
		}
	}

	.documents {

		background: #a7a9ac;
		margin: 20px 0;

		h1 {
			color: #fff;
			padding: 15px;
			border-bottom: solid 1px rgba(0,0,0,0.1);
			font-size: 24px;
			margin-bottom: 0;
		}

		.nav {
			background: none;

			li {
				width: 50%;
				float: left;
			}

			a {
				color: #fff;

				&:hover {
					color: #333;
				}
			}
		}
	}

	.footer-links {

		.make-row();

		li {
			.make-column(3,3,3,3);

			a {
				display: block;
				text-align: center;
				font-size: 14px;
			}
		}

		svg {
			.circle {
				transition: transform 0.75s ease;
				transform: rotateY(0deg);
				transform-origin: center center;
			}
		}

		a:hover {
			cursor: pointer;
			text-decoration: none;

			svg {
				.circle {
					transform: scale(1.25);
					transform: rotateY(180deg);
				}
			}
		}

		#starfish-icon {
			.circle,
			.icon {
				fill: @starfish;
			}
			+span {
				color: @starfish;
			}
		}

		#turtle-icon {
			.circle,
			.icon,
			span {
				fill: @turtle;
			}
			+span {
				color: @turtle;
			}
		}

		#angelfish-icon {
			.circle,
			.icon {
				fill: @angelfish;
			}
			+span {
				color: @angelfish;
			}
		}

		#dolphin-icon {
			.circle,
			.icon {
				fill: @dolphin;
			}
			+span {
				color: @dolphin;
			}
		}
	}

	.related-programs {

		margin: 25px 0;

		p {
			color: @brand-primary;
			font-weight: 600;
		}

		ul {
			.make-row();

			li {
				.make-column(4,4,4,12);
			}
		}
	}
}

@media (max-width: @screen-xs-max) {

	.related-programs {

		img {
			margin: 20px auto;
			max-width: 250px;
		}
	}
}




